import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入动画库
import "animate.css"


if (window.innerWidth < 1024) {
  require('../src/assets/css/mobileCommon.scss')
} else {
  require('../src/assets/css/pcCommon.scss')
}



Vue.config.productionTip = false

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
