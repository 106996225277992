import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/pc',
    component: () => import('../views/pc/index.vue'),
    children: [
      { path: '/pc', redirect: 'home' },
      {
        path: 'home',
        component: () => import('../views/pc/home/index.vue'),
        meta: { title: '首页' }
      },
      {
        path: 'productIntroduction',
        component: () => import('../views/pc/productIntroduction/index.vue'),
        meta: { title: '产品介绍' }
      },
      {
        path: 'solution',
        component: () => import('../views/pc/solution/index.vue'),
        meta: { title: '解决方案' }
      },
      {
        path: 'technology',
        component: () => import('../views/pc/technology/index.vue'),
        meta: { title: '技术' }
      },
      {
        path: 'aboutUs',
        component: () => import('../views/pc/aboutUs/index.vue'),
        meta: { title: '关于我们' }
      },
      {
        path: 'contactUs',
        component: () => import('../views/pc/contactUs/index.vue'),
        meta: { title: '联系我们' }
      },
      {
        path: 'smartAquaculture',
        component: () => import('../views/pc/smartAquaculture/index.vue'),
        meta: { title: '智慧养殖系统' }
      },
      {
        path: 'meeting',
        component: () => import('../views/pc/meeting/index.vue'),
        meta: { title: '会议管理系统' }
      },
      {
        path: 'houqing',
        component: () => import('../views/pc/houqing/index.vue'),
        meta: { title: '后勤管理系统' }
      },
      {
        path: 'yinzhang',
        component: () => import('../views/pc/yinzhang/index.vue'),
        meta: { title: '印章管理系统' }
      },
      {
        path: 'survey',
        component: () => import('../views/pc/survey/index.vue'),
        meta: { title: '勘察系统开发' }
      },
      {
        path: 'school',
        component: () => import('../views/pc/school/index.vue'),
        meta: { title: '高校实验室' }
      },
      {
        path: 'huading',
        component: () => import('../views/pc/huading/index.vue'),
        meta: { title: '华鼎' }
      },
      {
        path: 'fixedAssets',
        component: () => import('../views/pc/fixedAssets/index.vue'),
        meta: { title: '资产管理系统' }
      },
      {
        path: 'apartment',
        component: () => import('../views/pc/apartment/index.vue'),
        meta: { title: '公寓' }
      },
    ]
  },
  {
    path: '/mobile',
    component: () => import('../views/mobile/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

const originalPush = VueRouter.prototype.push
 
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
